@import '../../../../App.scss';
#missingData {
	margin: 0;
	width: 210mm;
	height: 297mm;
	overflow: hidden;
	box-sizing: border-box;
  
	p {
		margin: 0;
	}
	.missingData {
		width: 210mm;
		box-sizing: border-box;
		padding: 20px;
		.missingData-intro{
			color: red;
			text-align: center;
		}
	}
	.missingData-content{
		width: 210mm;
		box-sizing: border-box;
		display: grid;
		grid-template-columns: 1fr 1fr;
		h1 span{
			font-size: 15px;
			font-weight: normal;
		}
	}
}